<template>
  <v-container
    class="px-lg-10 mx-0"
  >
    <v-card class="px-10 py-5">
      <v-form
        v-if="onAddState"
        ref="form"
      >
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-row>
              <v-col>
                <p class="font-weight-bold mb-n1">
                  Informasi Umum
                </p>
              </v-col>
            </v-row>

            <!-- <v-row class="mb-n5">
              <v-col>
                <v-select
                  v-model="divisi"
                  :items="dataDivisi"
                  label="Divisi"
                  item-text="name"
                  item-value="hash"
                  color="purple"
                  outlined
                  dense
                  :error-messages="errDivisi"
                  @change="getDataPosisi"
                />
              </v-col>
            </v-row>

            <v-row class="mb-n5">
              <v-col>
                <v-select
                  v-model="dataEmployee.position_hash"
                  :items="dataPosisi"
                  label="Posisi"
                  item-text="name"
                  item-value="hash"
                  color="purple"
                  outlined
                  dense
                  :error-messages="errPosisi"
                />
              </v-col>
            </v-row> -->

            <v-row class="mb-n5">
              <v-col>
                <v-select
                  v-model="dataEmployee.position_hash"
                  :items="dataPosisi"
                  label="Posisi"
                  item-text="pos_name"
                  item-value="pos_hash"
                  color="purple"
                  outlined
                  dense
                  :error-messages="errPosisi"
                />
              </v-col>
            </v-row>

            <v-row class="mb-n5">
              <v-col>
                <v-text-field
                  v-model="dataEmployee.travikr_mail"
                  label="Email Travikr"
                  color="purple"
                  outlined
                  dense
                  :error-messages="errEmailTravikr"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <p class="font-weight-bold mb-n1">
              Data Pribadi
            </p>
          </v-col>
        </v-row>

        <v-row class="mb-n5">
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-text-field
              v-model="dataEmployee.f_name"
              label="Nama Depan"
              color="purple"
              outlined
              dense
              :error-messages="errNamaDepan"
            />
          </v-col>
        </v-row>

        <v-row class="mb-n5">
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-text-field
              v-model="dataEmployee.l_name"
              label="Nama Belakang"
              color="purple"
              outlined
              dense
              :error-messages="errNamaBelakang"
            />
          </v-col>
        </v-row>

        <v-row class="mb-n5">
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-text-field
              v-model="dataEmployee.personal_mail"
              label="Email Pribadi"
              color="purple"
              outlined
              dense
              :error-messages="errEmailPribadi"
            />
          </v-col>
        </v-row>

        <v-row class="mb-n5">
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-text-field
              v-model="dataEmployee.personal_phone"
              label="Nomor HP Pribadi"
              color="purple"
              outlined
              dense
              :error-messages="errNoHp"
            />
          </v-col>
        </v-row>

        <v-row class="mb-2 mt-2">
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <p class="font-weight-bold mb-n1">
              Data Alamat
            </p>
          </v-col>
        </v-row>

        <v-row class="mb-n5">
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-autocomplete
              v-model="dataEmployee.add_hash"
              :items="dataKotaKecamatan"
              :loading="isLoading"
              label="Kota / Kecamatan"
              item-text="string"
              item-value="hash"
              color="purple"
              placeholder="Ketik kota atau kecamatan anda..."
              outlined
              dense
              :search-input.sync="search"
              :error-messages="errKotaKecamatan"
              @change="getDataKodePos"
            />
          </v-col>
        </v-row>

        <v-row class="mb-n5">
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-select
              v-model="dataEmployee.add_pos"
              :items="dataKodePos"
              label="Kode Pos"
              color="purple"
              outlined
              dense
              :error-messages="errKodePos"
            />
          </v-col>
        </v-row>

        <v-row class="mb-n5">
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <!-- <v-text-field
            v-model="dataEmployee.add_string"
            label="Alamat"
            color="purple"
            outlined
            dense
            :error-messages="errAlamat"
          /> -->
            <v-textarea
              v-model="dataEmployee.add_string"
              label="Alamat"
              outlined
              color="purple"
              no-resize
              dense
              rows="3"
              :error-messages="errAlamat"
            />
          </v-col>
        </v-row>

        <v-row
          class="py-8"
        >
          <v-col
            cols="12"
            sm="6"
            md="6"
          >
            <div class="d-flex justify-center">
              <v-btn
                color="orange"
                @click="cancelButtonClick"
              >
                CANCEL
              </v-btn>
              <v-btn
                color="purple"
                :disabled="disableSaveButton"
                @click="saveButtonClick"
              >
                SAVE
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>

      <v-row v-else>
        <v-col>
          <div class="px-5 py-5 d-flex justify-center">
            <v-img
              max-width="250"
              contain
              src="@/assets/travikr-berhasil.png"
            />
          </div>

          <div class="px-5 pb-2 font-weight-bold text-h3 d-flex justify-center">
            Pendaftaran Berhasil
          </div>

          <!-- <div class="px-5 pt-5 font-weight-bold text-h3 d-flex justify-center">
            Email : {{ registeredEmail }}
          </div> -->

          <!-- <div class="px-5 pb-5 pt-2 font-weight-bold text-h3 d-flex justify-center">
            Password : {{ defaultPassword }}
          </div> -->

          <div class="px-5 py-5 text-h4 d-flex justify-center">
            {{ saveSuccessMessage }}
          </div>

          <div class="px-5 py-5 d-flex justify-center">
            <v-btn
              width="300"
              color="purple"
              @click="backButtonClick"
            >
              KEMBALI
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      employee: [],
      dataEmployee: {
        position_hash: '',
        f_name: '',
        l_name: '',
        travikr_mail: '',
        personal_mail: '',
        personal_phone: '',
        add_hash: '',
        add_pos: '',
        add_string: '',
      },
      isLoading: false,
      search: null,
      kotaKecamatan: '',
      divisi: '',
      posisi: '',
      dataKotaKecamatan: [],
      dataKodePos: [],
      dataDivisi: [],
      dataPosisi: [],
      errNamaDepan: '',
      errNamaBelakang: '',
      errEmailTravikr: '',
      errEmailPribadi: '',
      errDivisi: '',
      errPosisi: '',
      errNoHp: '',
      errKotaKecamatan: '',
      errKodePos: '',
      errAlamat: '',
      onAddState: true,
      saveSuccessMessage: 'Pendaftaran berhasil',
      registeredEmail: 'admin@travikr.com',
      defaultPassword: 'default_password',
      disableSaveButton: false,
    }),

    computed: {},

    watch: {
      search (val) {
        if (val === null) return
        if (val.trim().length < 3) return
        if (/[^a-zA-Z]/.test(val)) return
        this.getDataKotaKecamatan(val)
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        this.getDataPosisi()
      },

      async saveButtonClick () {
        this.disableSaveButton = true

        if (!this.fieldValidation()) return

        const requestBody = this.dataEmployee

        // console.log(requestBody)

        await axios.post('/t/employee/create', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message)
            this.registeredEmail = res.data.data.email
            this.defaultPassword = res.data.data.pass
            this.saveSuccessMessage = res.data.data.msg
            this.onAddState = false
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })

        this.disableSaveButton = false
      },

      // async getDataDivisi () {
      //   const requestBody = {}

      //   await axios.post('/t/getDivision', requestBody).then((res) => {
      //     if (res.data.status === 200) {
      //       this.dataDivisi = res.data.data.div
      //     }
      //   }).catch((e) => {
      //     e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
      //   })
      // },

      async getDataPosisi (divisi) {
        // const requestBody = {
        //   div_hash: divisi,
        // }

        await axios.post('/t/employee/getRoles').then((res) => {
          if (res.data.status === 200) {
            this.dataPosisi = res.data.data.roles
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })
      },

      async getDataKotaKecamatan (keywords) {
        const requestBody = {
          q: keywords,
        }

        await axios.post('/g/allAdd', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.dataKotaKecamatan = res.data.data.address
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })
      },

      async getDataKodePos (addressHash) {
        const requestBody = {
          addHash: addressHash,
        }

        await axios.post('/g/allAddPos', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.dataKodePos = res.data.data.posList
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })
      },

      backButtonClick () {
        this.onAddState = true
        this.$router.push({ name: 'Employee' })
      },

      cancelButtonClick () {
        this.$router.push({ name: 'Employee' })
      },

      fieldValidation () {
        let errCount = 0

        if (this.dataEmployee.f_name.trim().length === 0) {
          this.errNamaDepan = 'Nama Depan harus diisi'
          errCount++
        } else {
          this.errNamaDepan = ''
        }

        if (this.dataEmployee.l_name.trim().length === 0) {
          this.errNamaBelakang = 'Nama Belakang harus diisi'
          errCount++
        } else {
          this.errNamaBelakang = ''
        }

        if (this.dataEmployee.travikr_mail.trim().length === 0) {
          this.errEmailTravikr = 'Email Travikr harus diisi'
          errCount++
        } else if (!/@travikr\.com$/.test(this.dataEmployee.travikr_mail.trim())) {
          this.errEmailTravikr = 'Harus diisi domain email @travikr.com'
          errCount++
        } else {
          this.errEmailTravikr = ''
        }

        if (this.dataEmployee.personal_mail.trim().length === 0) {
          this.errEmailPribadi = 'Email Pribadi harus diisi'
          errCount++
        } else if (!/.+@.+\..+/.test(this.dataEmployee.personal_mail.trim())) {
          this.errEmailPribadi = 'Email yang anda isi tidak valid'
          errCount++
        } else {
          this.errEmailPribadi = ''
        }

        // if (this.divisi === '') {
        //   this.errDivisi = 'Divisi harus diisi'
        //   errCount++
        // } else {
        //   this.errDivisi = ''
        // }

        if (this.dataEmployee.position_hash === '') {
          this.errPosisi = 'Posisi harus diisi'
          errCount++
        } else {
          this.errPosisi = ''
        }

        if (this.dataEmployee.personal_phone.trim().length === 0) {
          this.errNoHp = 'Nomor HP harus diisi'
          errCount++
        } else if (!/^\d+$/.test(this.dataEmployee.personal_phone.trim())) {
          this.errNoHp = 'Nomor HP hanya boleh diisi angka'
          errCount++
        } else if (this.dataEmployee.personal_phone.trim().slice(0, 2) !== '08') {
          this.errNoHp = 'Nomor HP harus diawali dengan 08'
          errCount++
        } else {
          this.errNoHp = ''
        }

        if (this.dataEmployee.add_hash.trim().length === 0) {
          this.errKotaKecamatan = 'Kota atau kecamatan harus diisi'
          errCount++
        } else {
          this.errKotaKecamatan = ''
        }

        if (this.dataEmployee.add_pos === '') {
          this.errKodePos = 'Kode Pos harus diisi'
          errCount++
        } else {
          this.errKodePos = ''
        }

        if (this.dataEmployee.add_string.trim().length === 0) {
          this.errAlamat = 'Alamat harus diisi'
          errCount++
        } else {
          this.errAlamat = ''
        }

        if (errCount > 0) return false

        return true
      },
    },
  }
</script>
